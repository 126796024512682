<template>
  <div class="text botter-conversation botter-hero">
    <div class="d-flex align-end" :class="getAlignment()">
      <user-chat-image
        :sender="con.sender"
        v-if="con.type === 'bot' && lastMessageGroup"
      />
      <div class="full-width">
        <div class="botter-text" :class="getColor()" v-if="con.payload">
          <p
            v-html="common.formatText(con.payload)"
            :class="common.getTextAlignment(con.payload)"
          ></p>
        </div>
      </div>
    </div>
    <div class="botter-buttons-list">
      <div class="d-block" v-for="(btn, index) in con.actions" :key="index">
        <a
          :href="`tel:${btn.value}`"
          v-if="btn.type === 'call'"
          target="_blank"
          :class="{ 'button-disabled': inputDisabled }"
        >
          <b-btn>
            {{ btn.title }}
          </b-btn>
        </a>
        <a
          :href="btn.value"
          target="_blank"
          v-if="btn.type === 'openUrl'"
          :class="{ 'button-disabled': inputDisabled }"
        >
          <b-btn>
            {{ btn.title }}
          </b-btn>
        </a>
        <div
          @click="postBack(btn, index)"
          :class="{
            'button-disabled':
              inputDisabled ||
              (clicked.includes(index) &&
                $store.state.core.widgetParameters.buttonsSingleClick),
          }"
        >
          <b-btn v-if="btn.type === 'postBack'">
            {{ btn.title }}
          </b-btn>
        </div>
        <div
          @click="callEvent(btn.value)"
          :class="{ 'button-disabled': inputDisabled }"
        >
          <b-btn v-if="btn.type === 'event'">
            {{ btn.title }}
          </b-btn>
        </div>
      </div>
    </div>
    <div
      v-if="lastMessageGroup"
      class="chat-date d-flex"
      :class="getAlignment()"
    >
      {{ common.formatDate(con.date) }}
    </div>
  </div>
</template>

<script>
import userChatImage from "./common/userChatImage";
import common from "../../utils/common";
export default {
  name: "textData",
  components: {
    userChatImage,
  },
  props: {
    con: {
      type: Object,
      default: () => {
        return {};
      },
    },
    index: {
      type: Number,
      default: () => {
        return 0;
      },
    },
  },
  data() {
    return {
      common,
      clicked: [],
    };
  },
  methods: {
    getAlignment() {
      if (this.con.type === "user") {
        return "justify-end";
      } else if (this.con.type === "bot") {
        return "justify-start";
      }
    },
    getColor() {
      if (this.con.type === "user") {
        return "botter-user-text";
      } else if (this.con.type === "bot") {
        return "botter-bot-text";
      }
    },
    postBack(data, index) {
      this.clicked.push(index);
      this.$store.dispatch("pushMessage", {
        slug: "text",
        payload: data.title,
        type: "user",
        lastMessageGroup: true,
      });
      this.$emit("postBack", data);
    },
    callEvent(value) {
      this.$emit("postEvent", value);
      try {
        webkit.messageHandlers.cordova_iab.postMessage(
          JSON.stringify({ msg_code: value })
        );
      } catch (e) {
        // alert(e.message)
      }
    },
  },
  computed: {
    lastMessageGroup() {
      return this.$store.state.messages.conversationList[this.index]
        .lastMessageGroup;
    },
    inputDisabled() {
      return this.$store.state.messages.inputDisabled;
    },
  },
};
</script>

<style lang="scss">
.botter-buttons-list {
}
.botter-buttons-list > div {
  margin-right: 10px;
  margin-top: 10px;
}
</style>
