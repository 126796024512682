import common from "../../utils/common";

export const messages = {
  state: {
    conversationList: [],
    connected: false,
    inputDisabled: false,
    startNew: false,
    initBlock: null,
    typing: false,
    sessionActive: false,
    socketObj: {},
  },
  mutations: {
    SET_CONNECTED(state, connected) {
      state.connected = connected;
    },
    SET_CONVERSATION_LIST(state, conversationList) {
      state.conversationList = conversationList;
    },
    SET_INPUT_DISABLED(state, inputDisabled) {
      state.inputDisabled = inputDisabled;
    },
    SET_START_NEW(state, startNew) {
      state.startNew = startNew;
    },
    SET_INIT_BLOCK(state, initBlock) {
      state.initBlock = initBlock;
    },
    SET_SESSION_ACTIVE(state, sessionActive) {
      state.sessionActive = sessionActive;
    },
    SET_SOCKET_OBJ(state, socketObj) {
      state.socketObj = socketObj;
    },
    PUSH_MESSAGE(state, message) {
      message.date = new Date();
      message.text ? (message.text = common.sanitizeString(message.text)) : "";
      message.payload
        ? (message.payload = common.sanitizeString(message.payload))
        : "";

      if (
        state.conversationList[state.conversationList.length - 1] &&
        state.conversationList[state.conversationList.length - 1].type ===
          message.type
      ) {
        state.conversationList[
          state.conversationList.length - 1
        ].lastMessageGroup = false;
      }

      if (message.isTyping) {
        message.slug = "typing";
      }

      if (message.slug !== "typing") {
        state.conversationList.push(message);
      } else {
        if (state.conversationList.length) {
          if (
            state.conversationList[state.conversationList.length - 1].slug !==
            "typing"
          ) {
            state.conversationList.push(message);
            if (
              state.conversationList[state.conversationList.length - 1] &&
              state.conversationList[state.conversationList.length - 1].slug ===
                "typing"
            ) {
              setTimeout(() => {
                if (
                  state.conversationList[state.conversationList.length - 1] &&
                  state.conversationList[state.conversationList.length - 1]
                    .slug === "typing"
                ) {
                  state.conversationList.splice(
                    state.conversationList.length - 1,
                    1
                  );
                }
              }, state.conversationList[state.conversationList.length - 1].duration);
            }
          }
        }
      }

      if (
        state.conversationList[state.conversationList.length - 2] &&
        state.conversationList[state.conversationList.length - 2].slug ===
          "typing"
      ) {
        if (
          state.conversationList[state.conversationList.length - 1].type ===
          "user"
        ) {
          let con = {
            ...state.conversationList[state.conversationList.length - 2],
          };
          state.conversationList[state.conversationList.length - 2] = {
            ...state.conversationList[state.conversationList.length - 1],
          };
          state.conversationList[state.conversationList.length - 1] = {
            ...con,
          };
        } else {
          if (
            state.conversationList[state.conversationList.length - 1].slug ===
            "typing"
          ) {
            // state.conversationList.splice(state.conversationList.length-1,1)
          } else {
            state.conversationList.splice(state.conversationList.length - 2, 1);
          }
        }
        common.scrollHeight();
      }
    },
  },
  actions: {
    setConversationList({ commit }, conversationList) {
      commit("SET_CONVERSATION_LIST", conversationList);
    },
    pushMessage({ commit }, message) {
      commit("PUSH_MESSAGE", message);
    },
    setConnected({ commit }, connected) {
      commit("SET_CONNECTED", connected);
    },
    setInputDisabled({ commit }, setInputDisabled) {
      commit("SET_INPUT_DISABLED", setInputDisabled);
    },
    setStartNew({ commit }, startNew) {
      commit("SET_START_NEW", startNew);
    },
    setSessionActive({ commit }, sessionActive) {
      commit("SET_SESSION_ACTIVE", sessionActive);
    },
    setSocketObject({ commit }, socketObj) {
      commit("SET_SOCKET_OBJ", socketObj);
    },
    setInitBlock({ commit }, initBlock) {
      commit("SET_INIT_BLOCK", initBlock);
    },
  },
};
