<template>
  <div id="dynamicCss">
    <v-style
      v-if="
        !isStrictEnvironment &&
        widgetParameters.font &&
        !widgetParameters.customFont
      "
    >
      @import url('https://fonts.googleapis.com/css?family={{
        widgetParameters.font
      }}');
    </v-style>
    <v-style>
      .botter--widget p , .botter--widget h1, .botter--widget h2,
      .botter--widget h3, .botter--widget h4, .botter--widget h5,
      .botter--widget h6, .botter--widget span, .botter--widget label, input ,
      .booking-steps { font-family: {{ widgetFont }}, sans-serif !important; }
      #botter-widget .botter-user-text { background-color:
      {{ widgetParameters.userSideColor }} !important; } #botter-widget
      .botter-user-text p , #botter-widget .botter-user-text .v-icon ,
      #botter-widget .v-application .botter--widget .botter-user-text
      .botter-hyber-link { color:
      {{ widgetParameters.userSideFontColor }} !important; } #botter-widget
      .botter-bot-text , #botter-widget .botter-gallery .botter-gallery-item ,
      #botter-widget .botter-map-card , #botter-widget .botter-book-card {
      background-color: {{ widgetParameters.chatBotAnswerColor }} !important;
      color: {{ widgetParameters.chatBotAnswerFontColor }} !important; }
      #botter-widget .botter-gallery .botter-gallery-item p , #botter-widget
      .botter-gallery .botter-gallery-item h3, #botter-widget .v-application
      .botter--widget .botter-hyber-link { color:
      {{ widgetParameters.chatBotAnswerFontColor }} !important; } #botter-widget
      .botter-bot-text p , #botter-widget .botter-gallery-item{ color:
      {{ widgetParameters.chatBotAnswerFontColor }} !important; } #botter-widget
      .botter-intro .botter-intro-header , #botter-widget .botter-modal-head ,
      #botter-widget .botter-intro .botter-intro-header h3 , #botter-widget
      .botter-intro .botter-intro-header p , #botter-widget .botters-agents-list
      h5 , #botter-widget .botters-agents-list .botter-agent-status p{ color:
      {{ widgetParameters.headerFontColor }} !important; } #botter-widget
      .botter-intro .botter-intro-header , #botter-widget .botter-modal-head {
      background: {{ widgetParameters.headerColor }} !important; }
      #botter-widget .botter-modal-toggle-btn { background-color:
      {{ widgetParameters.bubbleButtonColor }} !important; } #botter-widget
      .botter--widget p { font-size: {{ widgetParameters.fontSize }}px
      !important; } #botter-widget .botter-modal-toggle-btn-whatsapp {
      background-color: {{ widgetParameters.whatsAppBtnColor }} !important; }
      #botter-widget .botter-modal-toggle-btn-web { background-color:
      {{ widgetParameters.webBtnColor }} !important; } #botter-widget
      .botter-btn { color: {{ widgetParameters.buttonsColor }} !important;
      background-color:
      {{ widgetParameters.buttonsBackgroundColor }} !important; border-color:
      {{ widgetParameters.buttonsBorderColor }} !important; } #botter-widget
      .intro-button-item { background-color:
      {{ widgetParameters.introButtonsBackground }} !important; color:
      {{ widgetParameters.introButtonsColor }} !important; }
    </v-style>
    <v-style v-if="widgetParameters.position === 'left'">
      #botter-widget .botter-modal , #botter-widget .botter-modal-toggle-btn,
      #botter-widget .botter-offline-message , #botter-widget
      .botter-channels-btns-wrapper { right: auto !important; left: 25px; }
      #botter-widget .botter-offline-message-icon { right: auto !important;
      left: 7px; }
    </v-style>
    <v-style v-if="widgetParameters.lang === 'ar_AR'">
      #botter-widget .botter--widget { direction: rtl; } #botter-widget
      .botter-wdiget-body .botter-chat-action , #botter-widget
      .botter-modal-head , #botter-widget .botter-wdiget-body
      .botter-modal-chat{ direction: ltr; } #botter-widget
      .v-text-field--outlined .v-input__append-outer , #botter-widget
      .botter-continue .mdi{ transform: rotate(180deg); } #botter-widget
      .botter-faq-search .v-input__slot { padding: 0 12px; border-radius: 0px
      5px 5px 0px !important; } #botter-widget .botter-intro
      .botter-close-mobile { left: 41px; right: auto !important; }
      #botter-widget .botter-intro .botter-intro-forms { text-align: right
      !important; } #botter-widget .booking-steps{ direction: rtl; }
      #botter-widget .booking-steps .step-actions .v-icon{ transform:
      rotate(180deg); } #botter-widget .booking-steps .v-date-picker-header
      .v-icon { transform: rotate(180deg); } #botter-widget .booking-steps h2 {
      margin-right: 0; margin-left: 50px }
    </v-style>
    <v-style v-if="widgetParameters.chatBubble === 'rounded'">
      #botter-widget .botter-bot-text { border-radius: 0px 20px 20px 20px
      !important; } #botter-widget .botter-user-text { border-radius: 20px 0
      20px 20px !important; }
    </v-style>
    <v-style v-if="widgetParameters.chatBubble === 'curvey'">
      #botter-widget .botter-bot-text { border-radius: 0px 5px 5px 5px
      !important; } #botter-widget .botter-user-text { border-radius: 5px 0 5px
      5px !important; }
    </v-style>
    <v-style v-if="widgetParameters.logoColorType === 'white'">
      #botter-widget .botter-intro-header img { filter: brightness(0) invert(1);
      }
    </v-style>
    <v-style v-if="widgetParameters.botAvatarColorType === 'white'">
      #botter-widget .botter-bot-chat-image img , #botter-widget
      .botter-offline-message-avatar img , #botter-widget
      .botter-intro-form-card .botter-agent-avatar img { -webkit-filter:
      brightness(0) invert(1); filter: brightness(0) invert(1); }
    </v-style>
    <v-style v-if="widgetParameters.botAvatarColorType === 'colored'">
      #botter-widget .botter-bot-chat-image img{ filter: none !important; }
    </v-style>
    <v-style v-if="widgetParameters.openBubbleImageColorType === 'colored'">
      #botter-widget .botter-modal-toggle-btn .botter-modal-open-btn ,
      #botter-widget .botter-modal-toggle-btn-web .botter-modal-open-btn ,
      #botter-widget .botter-modal-toggle-btn-whatsapp .botter-modal-open-btn {
      filter: none !important; }
    </v-style>
    <v-style v-if="!inIframe">
      .botter-widget-body { background-image: url({{
        widgetParameters.demoBackground
      }}); background-size: 100%; } .widget-iframe-wrapper .botter-widget-body ,
      #botter-iframe .botter-widget-body { background-image: none !important; }
    </v-style>
    <v-style>
      #botter-widget .botter-bot-chat-image, #botter-widget
      .botter-offline-message .botter-offline-message-wrapper
      .botter-offline-message-avatar, #botter-widget .botter-intro-form-card
      .botter-agent-avatar { background-color:
      {{ widgetParameters.avatarBackgroundColor }} !important; }
    </v-style>
    <v-style>
      #botter-widget .botter-offline-message .botter-offline-message-wrapper
      .botter-offline-message-body { background-color:
      {{ widgetParameters.offLineMessageBackgroundColor }} !important; }
      #botter-widget .botter-offline-message path { fill:
      {{ widgetParameters.offLineMessageBackgroundColor }} !important; }
      #botter-widget .botter-offline-message .botter-offline-message-wrapper
      .botter-offline-message-body p { color:
      {{ widgetParameters.offLineMessageFontColor }} !important }
    </v-style>
    <v-style v-if="widgetParameters.openBubbleImageFullWidth">
      #botter-widget .botter-modal-toggle-btn .botter-modal-open-btn ,
      #botter-widget .botter-modal-toggle-btn-web .botter-modal-open-btn ,
      #botter-widget .botter-modal-toggle-btn-whatsapp .botter-modal-open-btn{
      width: 100%; height: 100%; }
    </v-style>
    <v-style v-if="widgetParameters.botAvatarHasFullWidth">
      #botter-widget .botter-modal-head .botter-agent-avatar img ,
      #botter-widget .botter-bot-chat-image img { height: 100%; width: 100%; }
      #botter-widget .botter-modal-head .botter-agent-avatar { padding: 0
      !important; }
    </v-style>
  </div>
</template>

<script>
import widgetParameters from "../utils/widgetParameters";

export default {
  name: "dynamicCSS",
  data() {
    return {
      widgetParameters: {},
      inIframe: false,
      isStrictEnvironment: false,
      widgetFont: "Roboto",
    };
  },
  created() {
    this.isStrictEnvironment = process.env["VUE_APP_IS_STRICT_ENVIRONMENT"]
      ? Boolean(parseInt(process.env.VUE_APP_IS_STRICT_ENVIRONMENT))
      : false;
  },
  mounted() {
    this.inIframe = window.top !== window.self;
    widgetParameters.getExternalApiData();
    let data = widgetParameters.getWidgetParameters();
    data.then((res) => {
      this.widgetParameters = res;
      this.$vuetify.theme.themes.light.primary =
        this.widgetParameters.mainColor;
      document.documentElement.style.setProperty(
        "--primaryColor",
        this.widgetParameters.mainColor
      );
      this.$i18n.locale = this.$store.state.core.locale;

      if (this.widgetParameters.font && !this.isStrictEnvironment) {
        this.widgetFont = this.widgetParameters.font;
      }

      //this.widgetFont = "WOFF Peugeot New-Regular"
      //this.widgetParameters.customFont = true
    });

    if (window.addEventListener) {
      window.addEventListener(
        "message",
        (e) => {
          if (e.data && e.data.mainColor) {
            this.widgetParameters = e.data;
            this.$vuetify.theme.themes.light.primary =
              this.widgetParameters.mainColor;
            document.documentElement.style.setProperty(
              "--primaryColor",
              this.widgetParameters.mainColor
            );
            this.$store.dispatch("setWidgetParameters", e.data);
          } else if (e.data.type === "reload") {
            location.reload();
          }
        },
        false
      );
    } else if (window.attachEvent) {
      window.attachEvent("onmessage", () => {});
    }
  },
  methods: {},
  watch: {
    "this.$store.state.core.locale": function () {
      console.log(this.$store.state.core.locale);
    },
  },
};
</script>

<style scoped></style>
